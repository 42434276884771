import React from 'react'
import PropTypes from 'prop-types'
import { v4 } from 'uuid'
import { Flex, Box, Text, Stack, Button } from '@chakra-ui/core'

import { H3 } from '../globals'
import Carousel from '../carousel'

export function LuxuryFeature({ icon, text }) {
  return (
    <Flex paddingY={2}>
      {icon}
      <Text marginLeft={2} fontSize="md">
        {text}
      </Text>
    </Flex>
  )
}

function LuxuryRoom({
  desc,
  title,
  featuresTitle,
  images,
  amenities,
  features,
  buttonText,
  reversed,
  onClick,
  ...restProps
}) {
  return (
    <Flex direction={{ base: 'column', lg: 'row' }} align={{ lg: 'center' }} {...restProps}>
      <Carousel borderRadius="15px" flex="1 1 50%" order={{ base: 0, lg: reversed && 1 }}>
        {images.map((image) => (
          <Box
            key={v4()}
            paddingBottom={{ base: '65%', lg: '60vh' }}
            backgroundImage={`url(${image})`}
            backgroundRepeat="no-repeat"
            backgroundSize="cover"
            backgroundPosition="center"
            onClick={onClick}
          />
        ))}
      </Carousel>

      <Box
        alignSelf="center"
        flex="1 1 50%"
        paddingY={4}
        paddingLeft={{ base: 0, lg: reversed ? 0 : 12 }}
        paddingRight={{ base: 0, lg: reversed && 12 }}
        order={{ base: 0, lg: reversed && 0 }}
      >
        <Flex
          direction={{ base: 'column', md: 'row' }}
          justify="space-between"
          alignItems={{ md: 'center' }}
          marginBottom={6}
        >
          <Box>
            <H3>{title}</H3>
            {amenities.length !== 0 && (
              <Stack direction="row">
                <Text fontSize="md" opacity="0.7">
                  {amenities.join(' · ')}
                </Text>
              </Stack>
            )}
          </Box>
          <Button variantColor="teal" size="md" marginTop={{ base: 6, md: 0 }} onClick={onClick}>
            {buttonText}
          </Button>
        </Flex>

        <Box>
          <Text
            borderBottom="1px solid"
            borderBottomColor="gray.300"
            marginBottom={6}
            paddingBottom={6}
          >
            {desc}
          </Text>
          <Box>
            <Text fontWeight="bold" marginBottom={6}>
              {featuresTitle}
            </Text>
            {features.length !== 0 && (
              <Stack isInline flexWrap="wrap" spacing={6} shouldWrapChildren>
                {features.map((feature) => React.cloneElement(feature, { key: v4() }))}
              </Stack>
            )}
          </Box>
        </Box>
      </Box>
    </Flex>
  )
}

LuxuryRoom.propTypes = {
  reversed: PropTypes.bool,
  desc: PropTypes.string,
  title: PropTypes.string,
  featuresTitle: PropTypes.string,
  images: PropTypes.array,
  amenities: PropTypes.array,
  features: PropTypes.arrayOf(
    PropTypes.shape({
      icon: PropTypes.node,
      text: PropTypes.string,
    })
  ),
  onClick: PropTypes.func,
}

LuxuryRoom.defaultProps = {
  reversed: false,
  desc: '',
  title: '',
  featuresTitle: '',
  images: [],
  amenities: [],
  features: [],
  onClick: () => {},
}

export default LuxuryRoom
