import React from 'react'
import { GrGroup, GrCafeteria, GrSpa, GrValidate, GrSwim, GrUserPolice } from 'react-icons/gr'
import { useTranslation } from 'gatsby-plugin-react-i18next'
import { graphql } from 'gatsby'
import { useSetRecoilState } from 'recoil'
import { Stack } from '@chakra-ui/core'

import { bookingParamsState } from '../store'

import Layout from '../components/layout'
import SEO from '../components/seo'
import Article from '../components/article'
import Section from '../components/section'
import LuxuryRoom, { LuxuryFeature } from '../components/luxuryRoom'

function LuxuryHomesPage({ data }) {
  const { t } = useTranslation()
  const setBookingParams = useSetRecoilState(bookingParamsState)

  const luxuryFeatures = t('apartments.features.items', { returnObjects: true })

  const getImagesFromData = (edgeName) => {
    return data[edgeName].edges.map((edge) => edge.node.childImageSharp.fixed.src)
  }

  return (
    <Layout title={t('apartments.title')}>
      <SEO title={t('apartments.title')} />

      <Article>
        <Section>
          <Stack spacing={16}>
            <LuxuryRoom
              title={t('apartments.casaDeAgua.title')}
              featuresTitle={t('apartments.features.title')}
              features={[
                <LuxuryFeature text={luxuryFeatures.relaxation} icon={<GrSpa size="1.5rem" />} />,
                <LuxuryFeature text={luxuryFeatures.chef} icon={<GrCafeteria size="1.5rem" />} />,
                <LuxuryFeature text={luxuryFeatures.families} icon={<GrGroup size="1.5rem" />} />,
                <LuxuryFeature text={luxuryFeatures.luxury} icon={<GrValidate size="1.5rem" />} />,
                <LuxuryFeature
                  text={luxuryFeatures.concierge}
                  icon={<GrUserPolice size="1.5rem" />}
                />,
              ]}
              desc={t('apartments.casaDeAgua.desc')}
              amenities={t('apartments.casaDeAgua.amenities').split('/')}
              onClick={() =>
                window.open(
                  'https://www.anayjose.com/casa-de-agua.html',
                  '_blank',
                  'noopener noreferrer'
                )
              }
              images={getImagesFromData('depaVeleta')}
              buttonText={t('commons.bookNow')}
            />
            <LuxuryRoom
              title={t('apartments.villaCoba.title')}
              featuresTitle={t('apartments.features.title')}
              features={[
                <LuxuryFeature text={luxuryFeatures.relaxation} icon={<GrSpa size="1.5rem" />} />,
                <LuxuryFeature text={luxuryFeatures.families} icon={<GrGroup size="1.5rem" />} />,
                <LuxuryFeature text={luxuryFeatures.luxury} icon={<GrValidate size="1.5rem" />} />,
                <LuxuryFeature text={luxuryFeatures.pool} icon={<GrSwim size="1.5rem" />} />,
              ]}
              desc={t('apartments.villaCoba.desc')}
              amenities={t('apartments.villaCoba.amenities').split('/')}
              onClick={() => setBookingParams({ id: '232', room: 'villacoba', isOpen: true })}
              images={getImagesFromData('depaVeletaChico')}
              buttonText={t('commons.bookNow')}
              reversed
            />
          </Stack>
        </Section>
      </Article>
    </Layout>
  )
}

export const query = graphql`
  query {
    depaVeleta: allFile(
      filter: { relativeDirectory: { eq: "depa-veleta" } }
      sort: { fields: name }
    ) {
      ...multipleFixedImage
    }

    depaVeletaChico: allFile(
      filter: { relativeDirectory: { eq: "depa-veleta-chico" } }
      sort: { fields: name }
    ) {
      ...multipleFixedImage
    }
  }
`

export default LuxuryHomesPage
